function LinksController($scope, $document, $log, jobEvents) {
  /* @public property*/
    
  //TODO: Handle counts based on list of job ids
  // To allow us to handle cases where a job is started 
  // Before the interface knows about it via submission listener
  var vm = this;
  vm.failedJobsCount = 0;
  vm.completedJobsCount = 0;
  vm.incompleteJobsCount = 0;
  vm.incompleteInProgress = 0;

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.clear, () => {
    $log.debug("received jobs.events.clear in navbar component");
    vm.incompleteJobsCount = 0;
    vm.completedJobsCount = 0;
    vm.failedJobsCount = 0;
    vm.incompleteInProgress = 0;
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.initialize, () => {
    $log.debug("received in jobs.events.initialize in navbar component");
  });

  // NOT working at the moment: TODO: re-enable incompleteJobsCount here, and remove from in started
  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.submitted, () => {
    $log.debug("received in jobs.events.submitted in navbar component");
    // vm.incompleteJobsCount++;
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.started, () => {
    vm.incompleteInProgress++;
    vm.incompleteJobsCount++;
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.failed, () => {
    $log.debug("received in jobs.events.failed in navbar component");
    vm.failedJobsCount++;
    
    if(vm.incompleteJobsCount) {
      vm.incompleteJobsCount--;
    }

    if(vm.incompleteInProgress) {
      vm.incompleteInProgress--;
    }
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.completed, () => {
    $log.debug("received in jobs.events.completed in navbar component");
    vm.completedJobsCount++;

    if(vm.incompleteJobsCount) {
      vm.incompleteJobsCount--;
    }

    if(vm.incompleteInProgress) {
      vm.incompleteInProgress--;
    }
  });

  // TODO: Implement. Will require use of tracking job counts;
  // $scope.$on(jobEvents.eventPrefix + jobEvents.events.deletion.completed, () => {
  //   $log.debug("received in jobs.events.deletion.completed in navbar component");
  //   if(vm.completedJobsCount++;

  //   if(vm.incompleteJobsCount) {
  //     vm.incompleteJobsCount--;
  //   }

  //   if(vm.incompleteInProgress) {
  //     vm.incompleteInProgress--;
  //   }
  // });
}

angular.module('sq.navbar.links.component', [])
.component('navbarLinks', {
  controller: LinksController,
  templateUrl: 'navbar/links/navbar.links.tpl.html',
});
